/*------------------------------------------------------------------
    [ CSS TABLE CONTENT ]
-------------------------------------------------------------------
  1.0 General Styles
  2.0
  3.0
  4.0
  5.0
  6.0
  7.0
  8.0
  9.0
  10.0
  Responsive

/* --------------------------- General --------------------------- */

#help-desk {
    font-size: 24px;
    text-transform: uppercase;
    /* float: right; */
    font-weight: 600;
    padding-left: 10px;
    color: #000000;
}
.help {
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 22px;
    border-left: 3px solid #e3e3e3;
}
@media (max-width: 480px) {
    #help-desk {
        font-size: 15px !important;
    }
    .help{
      margin-top: 18px !important;
    }
    .navbar-brand img{
      height: 50px !important;
    }
}
@media (max-width: 360px) {
    #help-desk {
        font-size: 15px !important;
    }
    .help{
      margin-top: 10px !important;
    }
    .navbar-brand img{
      height: 36px !important;
    }
    .break{
      margin-top: -15px !important;
    }
}


.navbar-brand{
  cursor: pointer;
}

.nav-pills.nav-pills-primary .nav-item .nav-link.active, .nav-pills.nav-pills-primary .nav-item .nav-link.active:focus, .nav-pills.nav-pills-primary .nav-item .nav-link.active:hover {
    background: #e14eca;
    background-image: linear-gradient(to bottom left,#e14eca,#ba54f5,#e14eca);
    background-size: 210% 210%;
    background-position: 100% 0;
}
.nav-pills .nav-item i {
    display: block;
    font-size: 24px;
    line-height: 60px;
}
.tech-quest i{
  font-size: 16px;
}
/*------------------------------------------------------------------
  General Styles
-------------------------------------------------------------------*/
input {
  outline: none;
}
/* .row-title {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: #2d2d2d;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    text-transform: uppercase;
} */

/*------------------------------------------------------------------
  noise-break-title
-------------------------------------------------------------------*/
.noise-break{
  background: linear-gradient(150deg, #281483 15%, #8f6ed5 70%, #d782d9 94%);
  /* background: linear-gradient(-150deg, #667eea 0%, #764ba2 97%); */
  background-image: url(./images/pattern_transparent.png), linear-gradient(-150deg, #667eea 0%, #764ba2 97%);
  background-size: 380px, contain;
  background-position: center bottom;
  justify-content: center;
  height: 250px;
}
.break{
  height: 350px;
}
.short{
  height: 180px !important;
}
.short .title{
  position: relative;
  margin-top: 0px !important;
  transform: translateY(100%);
}
.noise-break .title{
  display: block;
  line-height: 52px;
  text-align: center;
  text-transform: none;
  font-weight: 600;
  color: #ffffff;
  margin-top: 40px;
}
.noise-break .description{
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  display: block;
  margin-right: auto;
  margin-left: auto;
  color: #fafafa;
  padding-top: 20px;
  width: 90%;
  max-width: 650px;
}
.short .last-update{
  padding-top: 55px;
  text-align: center;
  color: #fafafa;
}
.last-update{
  padding-top: 10px;
  text-align: center;
  color: #fafafa;
}
.page-opacity{
  padding: 10px 0px;
}

.global-search{
  padding-top: 10px;
  text-align: center;
}
.search-field::-moz-placeholder { /* Firefox 19+ */
  color: #6a6a6a;
}
.trending-search{
  float: left;
  margin-top: -3px;
  margin-left: 20px;
}

.trending-search span.popular-keyword-title{
  letter-spacing: 0.4px;
  margin-right: 5px;
  color: #ffffff;
}

.trending-search a{
  color: #f2f2f2;
}

textarea.form-control{
  border: none;
  color: #6a6a6a;
  outline: 0;
  text-align: left;
  vertical-align: top;
  width: 100%;
  padding: 8px 12px;
  font-weight: 400;
  margin: 0 0 10px 0;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #cad1d7;
  min-height: 45px;
}

.form-control.header-search{
  display: block;
  color: red;
  box-shadow: inset 0 0 0 0 transparent;
  width: 100%;
  float: left;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  min-height: 52px;
}

.global-search .search-field{
  width: 100%;
  font-size: 17.1px;
  border-radius: 8px;
  padding: 0px 30px;
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 60px;
  text-align: left;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.1);
  transition-property: all;
  transition-duration: 120ms;
  color: #018EE1;
  /* border: 1px solid #e3e3e3; */
}

.global-search form{
  position: relative;
  margin-top: 10px;
}

.global-search form button{
  position: absolute;
  right: 20px;
  top: 30px;
  background: none;
  border: none;
  font-size: 26px;
  color: #c3c3c3;
  cursor: pointer;
  text-decoration: none;
}

/* Top */

.global-search-top .search-field-top {
  width: 100%;
  font-size: 18px;
  border-radius: 8px;
  padding: 0px  30px;
  border: none;
  margin-top: 15px;
  margin-bottom: 20px;
  height: 60px;
  text-align: left !important;
  /* border: 1px solid #e3e3e3; */
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14);
}

.global-search-top form {
  position: relative;
  margin-top: 10px;
}

.global-search-top form button {
  position: absolute;
  right: 20px;
  top: 22px;
  background: none;
  border: none;
  font-size: 30px;
  color: #6a6a6a;
  cursor: pointer;
  text-decoration: none;
}
.effective-date{
  padding-left: 10px;
}

/*------------------------------------------------------------------
  breadcrumbs
-------------------------------------------------------------------*/

.article-breadcrumbs-container {
  display: flex;
  flex-wrap: wrap;
  display: block;
  padding: 30px 10px 50px 10px;
}

.breadcrumbs-container {
  max-width: 960px;
  display: flex;
  flex-wrap: flex;
  padding: 30px 20px 105px;
  display: block;
}

.breadcrumbs{
  margin: 0px;
  margin-right: 1px;
  list-style: none;
  padding: 0px;
  display: inline-block;
}

.breadcrumbs li {
  color: #CACACA;
  font-size: 16px;
  display: inline-block;
}

.breadcrumbs ul {
  padding-left: 0px;
}

.breadcrumbs a{
  text-decoration: none;
  color: #018EE1;
  font-weight: 600;
}

.breadcrumbs li:after{
  content: "-";
  color: #adb5bd;
  padding: 5px 5px;
  font-weight: 600;
}
.breadcrumbs li:last-child{
  color: #6a6a6a !important;
  font-weight: 600;
}

.breadcrumbs li:last-child::after{
  content: " ";
}
/*------------------------------------------------------------------
  Frequently Asked Questions
-------------------------------------------------------------------*/
.faq{
  margin-bottom: 50px;
}
.faqs-wrapper{
  width: 25% !important;
}

.faq-search{
  margin-bottom: 50px;
}

.faq-search .search-field {
    width: 100%;
    font-size: 17.1px;
    border-radius: 8px;
    padding: 0px 30px;
    border: none;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 60px;
    text-align: left;
    transition-property: all;
    transition-duration: 120ms;
    background: #fafafa;
    border: 1px solid #e3e3e3;
    color: #018EE1;
    font-weight: 400;
}

.faq-search form button {
    position: absolute;
    right: 20px;
    top: 30px;
    background: none;
    border: none;
    font-size: 30px;
    color: #c3c3c3;
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

#faq .accordion .accordion-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 16px 48px 16px 16px;
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid #e3e3e3;
}
#faq .accordion .accordion-item:last-child{
  border-bottom: none;
}
#faq .accordion .accordion-item .anchor.active {
  color: #2d2d2d;
  font-weight: 600;
}
#faq .accordion .accordion-item .anchor::after {
  font-family: 'FontAwesome';
  content: '\f055';
  position: absolute;
  float: right;
  right: 16px;
  font-size: 26px;
  color: #6a6a6a;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  opacity: 0.5;
}

#faq .accordion .accordion-item .anchor.active::after {
  font-family: 'FontAwesome';
  content: '\f056';
  color: #6a6a6a;
  font-size: 26px;
  opacity: 0.5;
}
#faq .accordion .content {
  opacity: 0;
  padding: 0 16px;
  max-height: 0;
  overflow: hidden;
  clear: both;
  -webkit-transition: all 0.2s ease 0.15s;
  -o-transition: all 0.2s ease 0.15s;
  transition: all 0.2s ease 0.15s;
}
#faq .accordion .content p {
  font-size: 18px;
  font-weight: 400;
  color: #6a6a6a;
}
#faq .accordion .content.active {
  opacity: 1;
  padding: 16px;
  max-height: 100%;
  -webkit-transition: all 0.35s ease 0.15s;
  -o-transition: all 0.35s ease 0.15s;
  transition: all 0.35s ease 0.15s;
}

/*------------------------------------------------------------------
  Guide
-------------------------------------------------------------------*/
.guide{
  margin-bottom: 50px;
}
#guide .sliderContainer .content {
  opacity: 0;
  padding: 0 16px;
  max-height: 0;
  overflow: hidden;
  clear: both;
  -webkit-transition: all 0.2s ease 0.15s;
  -o-transition: all 0.2s ease 0.15s;
  transition: all 0.2s ease 0.15s;
}
#guide .sliderContainer .content p {
  font-size: 18px;
  font-weight: 400;
  color: #6a6a6a;
}
#guide .sliderContainer .content.active {
  opacity: 1;
  padding: 16px;
  max-height: 100%;
  -webkit-transition: all 0.35s ease 0.15s;
  -o-transition: all 0.35s ease 0.15s;
  transition: all 0.35s ease 0.15s;
}
#guide .subtle-wrap{
  margin-left: 30px !important;
  padding: 0px !important;
}
#guide .article-body ol li {
  font-weight: 500 !important;
}
#guide h5{
  font-weight: 600 !important;
}
.guide-container .article-body {
  font-size: 16px;
  line-height: 30px;
  word-wrap: break-word;
  display: flex;
  min-height: 200px;
  height: auto;
  padding-left: 20px;
}
.guide-container .article-body, .section-area .article-list {
  margin-top: 6px;
}

.guide-container .platform-nav {
  padding-left: 0;
}

.guide-container .article-body ol {
  list-style: none;
  padding: 0;
  margin: 0;
  counter-reset: list-counter;
  /* margin-bottom: 18px; */
  margin-top: 18px;
  color: #2C2D30;
}

.guide-container .article-body ol li {
  position: relative;
  padding-left: 35px;
  min-height: 24px;
  margin-top: 14px;
  margin-left: 0;
}

.guide-container .article-body a {
  color: #2D9EE0;
  text-decoration: none;
}
.guide-container .article-body p {
  font-weight: 400 !important;
  text-decoration: none;
  color: #2d2d2d;
}
.guide-container .article-body ol li:before {
  content: counter(list-counter,decimal);
  counter-increment: list-counter;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border-radius: 100%;
  color: white;
  background: #2ab27b;
}

.sliderContainer div {
    display: none;
}
.sliderContainer .activeContent {
    display: block;
}


/*------------------------------------------------------------------
  Troubleshoot
-------------------------------------------------------------------*/
.troubleshoot{
  margin-bottom: 50px;
}

#troubleshoot .accordion {
    max-width: 720px;
}

#troubleshoot .accordion .accordion-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 16px 48px 16px 16px;
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid #e3e3e3;
}
#troubleshoot .accordion .accordion-item:last-child{
  border-bottom: none;
}
#troubleshoot .accordion .accordion-item .anchor.active {
  color: #2d2d2d;
  font-weight: 600;
}
#troubleshoot .accordion .accordion-item .anchor::after {
  font-family: 'FontAwesome';
  content: '\f055';
  position: absolute;
  float: right;
  right: 16px;
  font-size: 26px;
  color: #6a6a6a;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  opacity: 0.5;
}

#troubleshoot .accordion .accordion-item .anchor.active::after {
  font-family: 'FontAwesome';
  content: '\f056';
  color: #6a6a6a;
  font-size: 26px;
  opacity: 0.5;
}
#troubleshoot .accordion .content {
  opacity: 0;
  padding: 0 16px;
  max-height: 0;
  overflow: hidden;
  clear: both;
  -webkit-transition: all 0.2s ease 0.15s;
  -o-transition: all 0.2s ease 0.15s;
  transition: all 0.2s ease 0.15s;
}
#troubleshoot .accordion .content p {
  font-size: 18px;
  font-weight: 400;
  color: #6a6a6a;
}
#troubleshoot .accordion .content.active {
  opacity: 1;
  padding: 16px;
  max-height: 100%;
  -webkit-transition: all 0.35s ease 0.15s;
  -o-transition: all 0.35s ease 0.15s;
  transition: all 0.35s ease 0.15s;
}


/*------------------------------------------------------------------
  Article body
-------------------------------------------------------------------*/

.main-column.article-main-column {
  float: left;
  clear: both;
  padding-top:20px;
  margin-bottom: 50px;
}

.subtle-wrap {
  background: #fff;
  overflow: hidden;
  padding:30px;
}

.article-body {
  font-size: 16px;
  line-height: 30px;
  word-wrap: break-word;
}

.article-body, .section-area .article-list {
  margin-top: 6px;
}

.platform-nav {
  padding-left: 0;
}

.article-body ol {
  list-style: none;
  padding: 0;
  margin: 0;
  counter-reset: list-counter;
  /* margin-bottom: 18px; */
  margin-top: 18px;
  color: #2C2D30;
}

.article-body ol li {
  position: relative;
  padding-left: 35px;
  min-height: 24px;
  margin-top: 14px;
  margin-left: 0;
}

.article-body a {
  color: #2D9EE0;
  text-decoration: none;
}
.article-body p {
  font-weight: 400 !important;
  text-decoration: none;
  color: #2d2d2d;
}
.article-body ol li:before {
  content: counter(list-counter,decimal);
  counter-increment: list-counter;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border-radius: 100%;
  color: white;
  background: #2ab27b;
}

.article-body img {
  border: 1px solid rgba(0, 0, 0, 0.07);
  height: auto;
  max-width: 50%;
  padding: 3px;
  margin-left: 50px;
  margin-top: 2px;
}
.stickybar {
  height: 25vh;
  min-height: 75vh;
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 5%;
}

.main {
  display: flex;
  flex-direction: column;
}
.main-wrapper {
  display: flex;
  flex-direction: column;
}
.main,
.stickybar {
  background-color: white;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}
/*------------------------------------------------------------------
  Article Vote & question
-------------------------------------------------------------------*/
.article-vote{
  border-top: 1px solid #e3e3e3;
}
.article-more-questions{
  padding-top: 30px;
  color: #2d2d2d;
  text-align: center;
}
.article-vote .article-vote-up,
.article-vote .article-vote-down{
    cursor: pointer;
    min-width: 125px;
    border: 2px solid #2ab27b;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    color: #2ab27b;
    text-decoration: none;
    padding: 10px 10px;
    text-align: center;
    margin: 0 5px;
}
.article-vote .article-vote-question a{
    text-align: center;
    padding-left: 40px;

}
.article-vote .article-vote-question {
    font-weight: 700;
    text-align: center;
    display: block;
    margin-top: 20px;
    margin-bottom: 25px;
}

/*------------------------------------------------------------------
  Related articles
-------------------------------------------------------------------*/
.related-articles .row-title{
  font-size: 20px;
  text-align: left;
  padding-left: 40px;
  color: #6a6a6a;
}
.related-articles ul li{
  list-style: none;
  line-height: 40px;
  font-size: 18px;
}
.related-articles-sidebar{
  font-weight: 400;
  font-size: 16px;
  color: #89898b;
}
.related-articles hr{
  margin-left: 40px;
}

/*------------------------------------------------------------------
  panel-sidebar
-------------------------------------------------------------------*/
main::before,
main::after {
    content: "";
    display: block;
    height: 0;
    overflow: hidden
}

main::after {
    clear: both
}

main {
    zoom: 1
}

@media screen and (min-width: 980px) {
    article {
        float: right;
        width: 100%;
    }
}
aside {
    box-sizing: border-box;
    margin-bottom: 30px;
    /* margin-top: 20px; */
}
@media screen and (min-width: 980px) {
    aside {
        /* margin-left: -350px; */
        float: left
    }
}
.scroll-y{
  height: 646px;
  overflow-y: scroll;
}
.scroll-y::-webkit-scrollbar {
  width: .4em;
}
.scroll-y::-webkit-scrollbar,
.scroll-y::-webkit-scrollbar-thumb {
  overflow:visible;
  border-radius: 4px;
}
.scroll-y::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.2);
}
.panel-sidebar {
  float: left;
  z-index: 10;
  top: 0;
}
.panel-sidebar.panel-sidebar-sticky{
	position: fixed;
  top: 0;
}
.panel-sidebar .visibility-internal{
	float: left;
  position: relative;
  top: 12px;
  margin-left: 15px;
  margin-right: 5px;
  z-index: 10;
}
.panel-sidebar .section-list a {
	-webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  display: block;
  margin: 0;
  position: relative;
  padding: 11px;
  border-top: 1px #e7e7e7 solid;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  color: #6A6A6A;
  cursor: pointer;
  font-weight: 500;
}
.panel-sidebar .section-list li:first-child a {
	border-top: none;
}
.panel-sidebar .section-list a.active {
	background: #018EE1 !important;
  outline: none !important;
  color: #FFFFFF;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.panel-sidebar .section-list a:hover {
  background-color: #EBF6FD;
  border: 1px solid #dcf0fb;
}
.panel-sidebar .section-list a:active,
.panel-sidebar .section-list a:focus{
	outline: none;
}
.browse-help-topics{
	border: 1px #e7e7e7 solid;
  border-radius: 4px;
  background: #fff;
  font-style: normal;
  font-weight: 500;
}
.browse-help-topics li:first-child .link {
  border-top: 0;
}
.browse-help-topics{
	border: 1px #e7e7e7 solid;
  border-radius: 4px;
  background: #fff;
  font-style: normal;
}
.browse-help-topics ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.accordion {
  /* margin-top: 50px; */
  margin-left: 0px !important;
  background: #FFFFFF;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding-left: 3px;
}
.accordion .link {
  cursor: pointer;
  display: block;
  padding: 15px 20px 13px 18px;
  border-top: 1px solid #e7e7e7;
  color: #6A6A6A;
  font-size: 14px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-weight: 600;
  min-width: 275px;
}
.accordion li i {
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 18px;
  color: #CACACA;;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.accordion li i.fa-chevron-down {
  right: 12px;
  left: auto;
  font-size: 16px;
}

.accordion li.open .link {
  color: #000000;
}

.accordion li.open i {
   color: #000000;
}

.accordion li.open i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.category{
  background: #ffffff;
  min-height: 1px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.boxed {
    border: 1px solid #e3e3e3;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    max-width: 100%;
}

.article-header .card_title{
  font-size: 24px;
  line-height: 1.38em;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
}
.category-question{
  margin-top: 20px;
}
/***************************** Sub Menu ****************************/


.submenu {
  display: none;
  background: #ffffff;
  font-size: 14px;
}
.submenu li a.active {
    font-weight: 600;
    border-left: 8px #F2F2F2 solid;
    padding-left: 7px;
}

/* .submenu li { border-bottom: 1px solid #4b4a5e; } */

.submenu a {
  display: block;
  text-decoration: none;
  color: #018EE1;
  padding: 12px;
  padding-left: 18px;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.submenu .btn-link{
  text-transform: none !important;
}

/*------------------------------------------------------------------
  Category - Side Page
-------------------------------------------------------------------*/
.section i{
  color: #018EE1;
  float: left;
  padding: 10px;
  font-size: 20px;
}

.section .landing-search-icon i{
  font-size: 30px !important;
  padding: 0px !important;
}

.article-promoted a {
  text-decoration: none;
  color: #6a6a6a;
  /* color: #018EE1; */
  font-weight: 600;
}
.article-promoted a:hover {
  text-decoration: none;
  color: #333333;
  /* color: #178CCC; */
  font-weight: 700;
}

.section-tree-with-article ul, ol {
  color: #6A6A6A;
  font-size: 16px;
  line-height: 28px;
}

.category-page {
  position: relative;
}

.category-page .category-container {
  /* max-width: 960px; */
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  display: table;
}

.category-page .category-trees {
  padding-left: 10px;
  padding-right: 10px;
  display: table-cell;
}

.category-page .category-list {
  padding-left: 10px;
  padding-right: 10px;
  width: 255px;
  display: none;
  padding-top: 10px;
  vertical-align: top;
}

.category-page .category-title h4{
  font-weight: 600;
}
.category-page .category-title p {
  font-weight: 400 ;
  padding-left: 20px;
  color: #2d2d2d;
}
.category-page .popular-topics{
  padding: 30px 0px;
}
@media (max-width: 800px) {
  .category-page .category-list {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 0px;
    display: table-cell;
  }
}
.section-tree-with-article ul,
.section-page ul {
  margin: 0px;
  padding: 0px;
}
.section ul li{
  padding-left: 10px;
  margin-left: 20px;
  list-style-type: upper-roman;
}
.section ul li ul li{
  padding-left: 10px;
  margin-left: 20px;
  list-style-type: none;
}
.section ul li ul li::before{
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #018EE1;
  left: 0px;
  margin-top: 10px;
  margin-left: 30px;
}
.section-tree-with-article .section,
.section-page .section {
  list-style: none;
  padding-bottom: 2rem !important;
  border-bottom: 1px solid #e3e3e3;
  padding-top: 2rem !important;
}

.section-tree-with-article .section h2,
.section-page .section h2 {
  font-weight: 600;
  color: #2d2d2d;
  font-size: 18px;
  line-height: 45px;
  padding: 0px;
  text-transform: none;
  /* margin-top: 0px; */
}

.section-tree-with-article .section .article-list,
.section-page .section .article-list {
  margin: 0;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

@media (max-width: 610px) {
  .section-tree-with-article .section .article-list,
  .section-page .section .article-list {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

.section-tree-with-article .section .article-list li,
.section-page .section .article-list li {
  line-height: 21px;
  padding-bottom: 10px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  margin-left: 45px;
  list-style: disc;
}

@media (max-width: 610px) {
  .section-tree-with-article .section .article-list li,
  .section-page .section .article-list li {
    line-height: 28px;
  }
}

.section-tree-with-article .section .article-list li span,
.section-page .section .article-list li span {
  display: none;
}

.section-tree-with-article .section .article-list li a b,
.section-page .section .article-list li a b {
  font-weight: 600;
}
.section-tree-with-article .section .article-list .badge-pills-component p{
  margin-bottom: 0px !important;
  margin-top: 5px;
}

.section-tree-with-article .section:first-child,
.section-page .section:first-child {
  padding-top: 0px !important;
}

.section-tree-with-article .section:last-child,
.section-page .section:last-child {
  border-bottom: 0px;
}

/*------------------------------------------------------------------
  Documentation
-------------------------------------------------------------------*/
.documentation-wrap{
  padding-top: 20px;
}
.documentation .circle{
  height:95px;
  width:95px;
  border-radius:50px;
  display:inline-block;
  text-align:center;
}
.documentation .type{
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 20px;
  font-size: 18px;
}
.documentation .doc-wrap-content{
  color:#2d2d2d;
  text-align:center;
  font-weight:normal;
  text-transform:none;
  font-size: 16px;
  padding-top: 20px;
}
.documentation .doc-wrap-content p{
  margin: 0 0 30px !important;
}
.documentation .button{
  margin-top: 35px;
  cursor: pointer;
  min-width: 125px;
  border: 2px solid #018EE1;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  color: #018EE1;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 18px;
  background-color: transparent;
}
.doc-hr{
  width: 90%;
}
.doc-section-wrap{
  padding-top: 20px;
  margin: 0 auto;
  margin-bottom: 50px;
}
/* .documentation .privacy-center{
  background: #5fcc34 url('./images/doc/privacy-center.svg');
}
.documentation .terms-of-service-circle{
  background: #ff7900 url('./images/doc/terms-of-service.svg');
}
.documentation .privacy-policy-circle{
  background: #22b1ff url('./images/doc/privacy-policy.svg');
}
.documentation .faqs{
  background: #FFC500 url('./images/doc/faq.svg');
}
.documentation .troubleshooting{
  background: #9B90DE url('./images/doc/troubleshoot.svg');
}
.documentation .wall-of-love{
  background: #ff1667 url('./images/doc/wall-of-love.svg');
} */

/*------------------------------------------------------------------
  Privacy Center
-------------------------------------------------------------------*/
.privacy-center .slug{
  float: right;
}

.privacy-center .title{
  font-weight: bold;
}
.privacy-center p{
  font-weight: 500;
  color: #6a6a6a;
}
.privacy-center h3{
  padding-bottom: 20px;
}
.privacy-center .section ul li {
  list-style-type: none !important;
}
/*------------------------------------------------------------------
  Wall of Love
-------------------------------------------------------------------*/
.Comments {
    /* background: rgba(231,247,255,.25); */
    color: #454545;
}
.CommentsCarrier {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}
.CommentsInner {
    max-width: 1000px;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 60px auto;
}
.CommentsInner h2 {
    font-size: 36px;
    text-align: center;
    color: #018EE1;
}
.CommentsInner h2 img {
    height: 50px;
    position: relative;
}
.CommentsInner p {
    font-size: 18px;
    text-align: center;
    padding: 20px 0;
}
.CommentsGridHolder {
    padding-top: 40px;
}
.CommentList {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 10px 0;
}
.CommentListInnerLeft {
    -ms-flex: 7 1;
    flex: 7 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: start;
    align-items: flex-start;
    font-size: .83em;
}
.avatar {
    width: 40px;
    border-radius: 50%;
    margin-right: 20px;
}
.CommentLeft {
    position: relative;
    left: 2px;
    top: 37px;
}
.CommentListInnerLeft {
    font-size: .83em;
}
.CommentBodyInner {
    padding: 15px;
}
.CommentBodyInner {
    font-size: 16px;
    color: #6a6a6a;
    border: 1px solid #018EE1;
    background: #fff;
    border-radius: 20px 20px 20px 20px;
}
.CommentBodyName {
    padding: 15px;
    white-space: pre-line;
    color: #018EE1;
    font-size: 16px;
}
.CommentListInnerRight {
    -ms-flex: 6 1;
    flex: 6 1;
    padding: 0 20px;
}
.CommentListInnerRight div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin: 10px 0;
    max-width: 300px;
}
.CommentListInnerRight div img:nth-child(2) {
    margin: 0 10px;
}
.CommentListInnerRight div:nth-child(2n) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
.CommentList:nth-child(2n) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
.em, .em-svg{
  height: 20px !important;
  width: 20px !important;
}

/*------------------------------------------------------------------
  Privacy Center
-------------------------------------------------------------------*/
.oath h5{
  font-weight: 500;
  text-transform: inherit;
  letter-spacing: normal;
}
/*------------------------------------------------------------------
  Responsive
-------------------------------------------------------------------*/
@media (max-width: 1200px) {

}
@media (max-width: 992px) {

}
@media (max-width: 830px) {

}
@media (max-width: 768px) {

}
@media (max-width: 480px) {
  #header-holder .search-field{
    width: 100% !important;
    float: left;
    margin-left: 0px !important;
  }
  #header-holder form button{
    right: 25px !important;
  }
  .article-breadcrumbs-container{
    padding: 24px;
  }
  .category-page .category-container{
    padding: 0px;
  }
  .section-tree-with-article .section, .section-page .section{
    padding-bottom: 5px;
  }
  .main-column.article-main-column{
    float: none;
    margin: 5px;
  }
  .subtle-wrap{
    padding: 5px;
  }
  .section-tree-with-article .section, .section-page .section{
    border-bottom: none;
  }
  .section-tree-with-article .section .article-list li, .section-page .section .article-list li{
    margin-left: 30px;
  }
  .noise-break .title{
    font-size: 26px;
    line-height: 40px;
    margin-top: 20px;
  }
  .noise-break .description{
    font-size: 18px;
    width: 100%;
  }
  .doc-wrap{
    width: 100%;
  }
  .article-breadcrumbs-wrap{
    padding: 30px 0px 30px 20px;
  }
  .documentation .doc-wrap-content{
    margin-bottom: 30px;
  }
  .doc-hr{
    width: 0px !important;
    margin: 0px;
  }
  .faq-search-container{
    padding: 0px;
  }
  .faq-search{
    margin-bottom: 10px;
  }
  .faq-search .search-field{
    padding: 0px 15px;
  }
  .faq{
    padding: 0px;
  }
  #faq .accordion .accordion-item{
    /* padding: 16px; */
  }
  #faq .accordion .content{
    padding: 16px 0px 16px 0px !important;
  }
  .article-more-questions{
    padding-top: 10px;
  }
  .related-articles .row-title{
    padding-left: 20px;
  }
  .related-articles hr{
    margin-left: 20px;
  }
  .related-articles ul{
    padding-left: 25px;
  }

}

@media (max-width: 460px) {
  .section-tree-with-article .section h2,
  .section-page .section h2 {
    font-size: 6vw;
    line-height: 9vw;
  }
}



/*  slider  */

.slider {
  margin: 80px 0;
  height: 356px;
  display: block;
  perspective: 1000px;
  position: relative;
  width: 100%;
}
.slider img {
  display: inline-block;
  width: 200px;
  position: absolute;
  backface-visibility: hidden;
  transition: .4s all;
}
.slider img:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background: linear-gradient(transparent, black);
}
.slider img.active {
  z-index: 4;
}
