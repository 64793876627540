/*------------------------------------------------------------------
    CSS TABLE CONTENT
-------------------------------------------------------------------
  1.0 General Styles
  2.0
  3.0
  4.0
  5.0
  6.0
  7.0
  8.0
  9.0
  10.0
  Responsive

/* --------------------------- General --------------------------- */
/* ::selection {
    background: #000000;
    color: #ffffff;
}

::-moz-selection {
    background: #000000;
    color: #ffffff;
}

::-webkit-selection {
    background: #000000;
    color: #ffffff;
} */

.align-center {
    text-align: center;
}

/* --------------------------- Global Classes --------------------------- */

.spacer {
	height: 10px;
}
.spacer-mini {
	height: 15px;
}
.spacer-small {
	height: 25px;
}
.spacer-medium {
	height: 50px;
}
.spacer-big {
	height: 100px;
}
.spacer-extra-big {
	height: 150px;
}
.loader{
  width: 30%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.error-404-stripe{
  background-color: #fafafa;
}
.error-404{
  width: 50%;
  margin: auto;
}

/*------------------------------------------------------------------
Landing
-------------------------------------------------------------------*/
.landing{
  padding: 0px 82px;
}
.landing .card{
  padding: 10px;
  border: none;
  background-color: rgba(255,255,255,0.0);
}
/* .landing-blocks {
  padding-left: 15px;
  padding-right: 15px;
} */
.digitalize{
  border-radius: 8px;
  background-color: rgba(57, 226, 129,0.1);
  /* border: 1px solid rgba(57, 226, 129,1) */
}
.communicate{
  border-radius: 8px;
   /* background-color: rgba(255, 45, 85,0.08); */
  /* border: 1px solid rgba(253, 156, 61,1) */
  background-color: rgba(255,59,48,.08);
}
.assess{
  border-radius: 8px;
   background-color: rgba(82, 93, 234,0.1);
  /* border: 1px solid rgba(82, 93, 234,1) */
}
.points-description{
  font-size: 1rem;
  line-height: 32px;
  font-weight: 600;
  color: #6a6a6a;
}

/*------------------------------------------------------------------
    Floating Blocks
-------------------------------------------------------------------*/

.info-blocks {
    position: relative;
}

.info-blocks .bg-color {
    background-color: #f6f9fc;
    position: absolute;
    width: 100%;
    height: 49%;
    left: 0;
    -webkit-transform: skewY(-10deg) scaleY(0.7);
    transform: skewY(-10deg) scaleY(0.7);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    z-index: -1;
}

#features .bg-color {
    background-color: #d6e0f5;
    position: absolute;
    width: 100%;
    height: -50%;
    left: 0;
    -webkit-transform: skewY(-10deg) scaleY(2.5);
    transform: skewY(-10deg) scaleY(2.5);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    z-index: -1;
}

.floatingblock {
    margin-top: 20px;
    z-index: 1;
    width: 90%;
    height: auto;
    max-width: 1100px;
    min-height: 0;
    /* border-radius: 10px; */
    background-color: #fff;
    border: 1px solid #e3e3e3;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14);
}

.floatingblock {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.floatingblockcolumns {
    display: inline-block;
    height: auto;
    margin-top: 60px;
    /* border-radius: 10px; */
}

.intro-cards {
    background-color: #FFFFFF;
    border-bottom: 1px solid #E3E3E3;
    border-top: 1px solid #E3E3E3;
}

@media screen and (max-width: 830px) {
    .intro-cards {
        margin-bottom: 22px;
        background-color: #f4f7fa;
        border: 1px solid #e3e3e3
    }
}

@media screen and (max-width: 700px) {
    .intro-cards {
        padding-bottom: 0px;
    }
}

@media screen and (max-width: 560px) {
    .intro-cards {
        margin-bottom: 0px;
    }
}

.intro-cards .intro-cards-container {
    margin: 0px auto;
}

.intro-cards .intro-card-container {
    width: 25%;
    display: inline-block;
    vertical-align: top;
    border-right: 0.3px solid #e9e9e9;
}

.intro-cards .intro-card-container:last-child {
    border-right: none;
}


@media screen and (max-width: 830px) {
    .intro-cards .intro-card-container {
        width: 49.6%;
    }
}

@media screen and (max-width: 700px) {
    .intro-cards .intro-card-container {
        -webkit-transform: translate(0px, 0px);
        -moz-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        -o-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
        width: 100%;
    }
}

.intro-cards .card {
    z-index: 2;
    padding: 36px 20px 0px;
    position: relative;
    transition-property: all;
    transition-duration: 170ms;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

@media screen and (min-width: 701px) {
    .intro-cards .card {
        background-color: #ffffff;
        min-height: 300px;
    }
}

@media screen and (max-width: 700px) {
    .intro-cards .card {
        padding: 0px;
    }
}

.intro-cards .card .tile {
    color: #2d2d2d;
    text-decoration: none;
    z-index: 1;
    display: inline-block;
}

@media screen and (max-width: 700px) {
    .intro-cards .card .tile {
        background-color: #FFFFFF;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        padding: 20px;
        width: 100%;
    }
}

.intro-cards .card:hover {
    z-index: 3;
}

@media screen and (min-width: 701px) {
    .intro-cards .card:hover {
        -webkit-transform: scale(1.075) rotate(0.0001deg);
        -moz-transform: scale(1.075) rotate(0.0001deg);
        -ms-transform: scale(1.075) rotate(0.0001deg);
        -o-transform: scale(1.075) rotate(0.0001deg);
        transform: scale(1.075) rotate(0.0001deg);
        background-color: #F4F7FA;
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    }
}

.intro-cards .card-title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
    margin-top: 0;
    color: inherit;
    font-weight: 600;
    padding-top: 5px;
}

.intro-cards .card-description {
    font-size: 16px;
    line-height: 25px;
    padding: 0;
    margin: 4px auto 0px;
    max-width: 340px;
    padding-top: 5px;
}

.intro-cards .card-image {
    width: 95px;
    height: 95px;
    border-radius: 50px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

/*------------------------------------------------------------------
 bubbles-section
-------------------------------------------------------------------*/
.darksection-bubbles {
    position: relative;
    z-index: 0;
    height: auto;
    background-color: #3F4959;
    background-image: linear-gradient(96deg, #202e3d, #385069 98%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.appbuttons {
    display: block;
    max-width: 388px;
    margin-right: auto;
    margin-left: auto;
}

.app-play-store{
  display: block;
  max-width: 250px;
  margin-right: auto;
  margin-left: auto;

}
.stores{
  float: left;

}

.storebuttons {
    display: block;
    max-width: 194px;
    margin-right: auto;
    margin-left: auto;
    opacity: .8;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 22px;
}
.storebuttons img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}
.wireframes img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}
.storebuttons:hover {
    opacity: 1;
}

.media-block {
    z-index: 2;
}

.primary-body{
    background-color: #f8f9fe;
}

/*------------------------------------------------------------------
	Text
-------------------------------------------------------------------*/

.text-roles {
    color: #2d2d2d !important;
		font-weight: 800 !important;
}

/*------------------------------------------------------------------
	User Roles
-------------------------------------------------------------------*/

.user-roles {
    position: relative;
    /* padding-top: 110px; */
}
.user-roles:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    background: #f6f9fc;
}

/*------------------------------------------------------------------
	Button
-------------------------------------------------------------------*/

.btn-admin {
    color: #fff;
    border-color: #FC708A;
    background-color: #FC708A;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-admin:hover {
    color: #fff;
    border-color: #F07289;
    background-color: #F07289;
}

.btn-admin:focus,
.btn-admin.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(94, 114, 228, .5);
}

.btn-admin.disabled,
.btn-admin:disabled {
    color: #fff;
    border-color: #FC708A;
    background-color: #FC708A;
}

.btn-admin:not(:disabled):not(.disabled):active,
.btn-admin:not(:disabled):not(.disabled).active,
.show > .btn-admin.dropdown-toggle {
    color: #fff;
    border-color: #FC708A;
    background-color: #FC708A;
}

.btn-admin:not(:disabled):not(.disabled):active:focus,
.btn-admin:not(:disabled):not(.disabled).active:focus,
.show > .btn-admin.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(94, 114, 228, .5);
}

.btn-teacher {
    color: #fff;
    border-color: #7bdf59;
    background-color: #7bdf59;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-teacher:hover {
    color: #fff;
    border-color: #7CD65D;
    background-color: #7CD65D;
}

.btn-teacher:focus,
.btn-teacher.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(94, 114, 228, .5);
}

.btn-teacher.disabled,
.btn-teacher:disabled {
    color: #fff;
    border-color: #7bdf59;
    background-color: #7bdf59;
}

.btn-teacher:not(:disabled):not(.disabled):active,
.btn-teacher:not(:disabled):not(.disabled).active,
.show > .btn-teacher.dropdown-toggle {
    color: #fff;
    border-color: #7bdf59;
    background-color: #7bdf59;
}

.btn-teacher:not(:disabled):not(.disabled):active:focus,
.btn-teacher:not(:disabled):not(.disabled).active:focus,
.show > .btn-teacher.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(94, 114, 228, .5);
}

.btn-student {
    color: #fff;
    border-color: #FFC500;
    background-color: #FFC500;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-student:hover {
    color: #fff;
    border-color: #F2BE0D;
    background-color: #F2BE0D;
}

.btn-student:focus,
.btn-student.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(94, 114, 228, .5);
}

.btn-student.disabled,
.btn-student:disabled {
    color: #fff;
    border-color: #FFC500;
    background-color: #FFC500;
}

.btn-student:not(:disabled):not(.disabled):active,
.btn-student:not(:disabled):not(.disabled).active,
.show > .btn-student.dropdown-toggle {
    color: #fff;
    border-color: #FFC500;
    background-color: #FFC500;
}

.btn-student:not(:disabled):not(.disabled):active:focus,
.btn-student:not(:disabled):not(.disabled).active:focus,
.show > .btn-student.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(94, 114, 228, .5);
}

.btn-parent {
    color: #fff;
    border-color: #a294f5;
    background-color: #a294f5;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-parent:hover {
    color: #fff;
    border-color: #9F92E9;
    background-color: #9F92E9;
}

.btn-parent:focus,
.btn-parent.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(94, 114, 228, .5);
}

.btn-parent.disabled,
.btn-parent:disabled {
    color: #fff;
    border-color: #a294f5;
    background-color: #a294f5;
}

.btn-parent:not(:disabled):not(.disabled):active,
.btn-parent:not(:disabled):not(.disabled).active,
.show > .btn-parent.dropdown-toggle {
    color: #fff;
    border-color: #a294f5;
    background-color: #9F92E9;
}

.btn-parent:not(:disabled):not(.disabled):active:focus,
.btn-parent:not(:disabled):not(.disabled).active:focus,
.show > .btn-parent.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(94, 114, 228, .5);
}


/*------------------------------------------------------------------
	icon
-------------------------------------------------------------------*/
.icon img{
  width: 50px;
}

.bg-admin {
    background-color: #FC708A !important;
}

a.bg-admin:hover,
a.bg-admin:focus,
button.bg-admin:hover,
button.bg-admin:focus {
    background-color: #F07289 !important;
}


.bg-teacher {
    background-color: #7bdf59 !important;
}

a.bg-teacher:hover,
a.bg-teacher:focus,
button.bg-teacher:hover,
button.bg-teacher:focus {
    background-color: #7CD65D !important;
}

.bg-student {
    background-color: #FFC500 !important;
}

a.bg-student:hover,
a.bg-student:focus,
button.bg-student:hover,
button.bg-student:focus {
    background-color: #F2BE0D !important;
}


.bg-parent {
    background-color: #a294f5 !important;
}

a.bg-parent:hover,
a.bg-parent:focus,
button.bg-parent:hover,
button.bg-parent:focus {
    background-color: #9F92E9 !important;
}

.unique-selling-points{
  background-color: #fff;
}
.multiple-role{
  width: 520px;
  height: auto;
}

/*------------------------------------------------------------------
  Mile stone of LMS
-------------------------------------------------------------------*/

.hiw-item div {
    opacity: 1;
    text-align: center;
    font-weight: bold;
    height: 80px;
    width: 80px;
    font-size: 24px;
    color: #F7F8FC;
    border-radius: 50%;
    padding: 22px 0 0;
    -webkit-box-shadow: 0 10px 20px 0 rgba(5, 16, 44, .15);
    box-shadow: 0 10px 20px 0 rgba(5, 16, 44, .15);
}

.line {
    position: absolute;
    width: 100%;
    margin-left: -33vw;
    margin-top: 45px;
    height: 6px;
    /* background-image: linear-gradient(-180deg, rgba(20, 56, 104, 0.2) 0%, rgba(33, 132, 171,0.2) 100%); */
    background-color: rgba(20,56,104,0.09);
    -webkit-box-shadow: 0 2px 5px 0 rgba(5, 16, 44, .07);
    box-shadow: 0 2px 5px 0 rgba(5, 16, 44, .07);
}

.bg-color-2 {
    /* background-image: linear-gradient(-180deg, #6326AA 0%, #5C1B84 100%); */
    background-image: linear-gradient(-180deg, #2184AB 0%, #143868 100%);
}

.bold {
    font-weight: bold;
    color: #2b2b2b;
    text-transform: uppercase;
    font-size: 16px;
}

.light {
    padding-top: 20px;
    color: #6a6a6a;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 10px;
    text-transform: initial;
}


/*-----------------------------------------------------------------------------------
  edu Timeline Style
-----------------------------------------------------------------------------------*/
.team{
  width: 100%;
  height: 450px;
}


.about-title {
    text-align: center;
    font-weight: 700;
    line-height: 42px;
    width: 100%;
}

.about p {
    text-align: center;
    font-size: 18px;
    line-height: 36px;
    width: 100%;
}

ul .about-uclid-description{
  list-style-type: circle;
}

.mission {
    width: 80%;
    margin: 0 auto;
}

.mission .title {
    text-align: left;
    font-weight: 700;
}

.mission .sub-title{
    text-align: left;
    font-weight: 600;
    text-transform: uppercase;
    color: #018ee1;
}

.mission p {
    text-align: left;
    font-size: 18px;
}

.mission-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.image-team {
    margin-top: 30px;
    width: 80%
}

.about-uclid {
    margin-bottom: 20px;
}

.about-uclid-title {
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
}

.about-uclid p {
    text-align: left;
    font-size: 18px;
    /* width: 75%; */
    margin: 0 auto;
    margin-bottom: 20px;
}
.about-uclid ul {
    list-style-position: inside;
    text-align: left;
    font-size: 18px;
    /* width: 75%; */
    margin: 0 auto;
}
.about-uclid li {
    margin-bottom: 10px;
    margin-left: 20px;
}

.work-ecosystem {
    width: 80%;
    margin: 0 auto;
}

.work-ecosystem .title {
    text-align: left;
    font-weight: 700;
    /* text-transform: uppercase; */
    line-height: 32px;
}

.work-ecosystem p {
    text-align: left;
    font-size: 18px;
}

.edu-timeline-block .header {
    text-align: center;
}

.edu-timeline-block .header .title-sm {
    font-weight: 700;
}

.edu-timeline-block .header p {
    font-size: 20px;
    color: #323545;
    line-height: 1.4;
    margin: 0 auto;
}

.about-panel.is-values {
    padding: 0px 20px;
}

.about-panel {
    width: 80%;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, .15) 0 2px 4px 1px;
}

.about-panel.is-values .is-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}

.about-panel.is-values .is-item:nth-child(1) .media-left {
    margin-right: 26px;
}

.media-body .media-heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}

.media-body {
    vertical-align: top;
}

.media img {
    width: 107px;
    max-width: none;
}
.news-card {
    margin-top: 50vw;
    padding: 15px 15px 15px;
    z-index: 9;
    cursor: pointer;
    background-color: rgb(250, 251, 255);
    overflow: hidden;
    width: 90%;
    position: absolute;
    border-radius: 0px 0px 10px 10px;
    -webkit-box-shadow: 0 10px 30px 0 rgba(5, 16, 44, .15);
    box-shadow: 0 10px 30px 0 rgba(5, 16, 44, .15);
    -webkit-transition: .4s;
    transition: .4s;
}

.newspostcardoverlay {
    min-height: 500px;
    border-radius: 10px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    margin: 0 auto;
    width: 80%;
    background-image: url(images/blog.jpg), linear-gradient(180deg, rgba(32, 46, 61, .25), #202e3d);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
}

.newspostcardoverlay:hover {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    box-shadow: 0 7px 34px 0 rgba(0, 0, 0, .35);
}

.newspostcardauthor {
    color: #ffffff;
    text-align: center;
    letter-spacing: 2.1px;
    text-transform: uppercase;
    padding-top: 23vw;
    font-weight: 400;
}

.newspostcardname {
    min-height: 0;
    border-bottom: 1px none hsla(0, 0%, 100%, .17);
    color: #fff;
    font-size: 24px;
    line-height: 39px;
}

.newspostcardname,
.newspostcardsummary {
    width: 80%;
    text-align: center;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.newspostcardline {
    display: block;
    width: 50px;
    height: 2px;
    margin-right: auto;
    margin-bottom: 23px;
    margin-left: auto;
    background-color: #fafafa;
}

.newspostcardsummary {
    padding-bottom: 40px;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
}

/*------------------------------------------------------------------
	Blog Posts
-------------------------------------------------------------------*/
.blog-title{
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  color: #2d2d2d;
}
.blog-heading{
  font-weight: 600;
  padding-top: 1rem;
}
.blog-content p{
  color: #6a6a6a;
  font-weight:400;
}
#post-content {
    padding-top: 20px;
    padding-bottom: 20px;
}

#post-content .post .post-thumbnail {
    width: 100%;
    /* height: 500px; */
    border-radius: 10px;
    /* background-image: url(../images/blog.jpg), linear-gradient(180deg, rgba(32, 46, 61, .25), #202e3d); */
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
}
#post-content .post .post-image-preview{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

#post-content .post .post-entry {
    padding: 30px 20px;
}

#post-content .post .post-entry .date-published {
    font-weight: 300;
    font-size: 15px;
    color: #cccccc;
}

#post-content .post .post-entry a {
    text-decoration: none;
}

#post-content .post .post-entry a .post-title {
    font-weight: 700;
    font-size: 35px;
    line-height: 43px;
    color: #5f6576;
    margin-bottom: 60px;
}

#post-content .post .post-entry a:hover .post-title,
#post-content .post .post-entry a:focus .post-title {
    color: #0397ff;
}

#post-content .post .meta .view {
    margin-right: 20px;
    color: #696969;
    font-size: 16px;
}

#post-content .post .meta .view:before {
    content: "\f06e";
    font-family: fontAwesome;
    color: #018EE1;
    margin-right: 10px;
}

#post-content .post .meta .like {
    margin-right: 20px;
    color: #696969;
}

#post-content .post .meta .like:before {
    content: "\f004";
    font-family: fontAwesome;
    color: #018EE1;
    margin-right: 10px;
}

#post-content .post .meta .like a {
    color: #696969;
}

#post-content .post .meta .like a:hover,
#post-content .post .meta .like a:focus {
    color: #0397ff;
}

#post-content .post .meta .comment {
    margin-right: 20px;
    color: #696969;
}

#post-content .post .meta .comment:before {
    content: "\f27a";
    font-family: fontAwesome;
    color: #756de7;
    margin-right: 10px;
}

#post-content #post-body .post .post-title {
    font-weight: 600;
    font-size: 22px;
    color: #2d2d2d;
    margin-bottom: 20px;
    margin-top: 30px;
    line-height: 30px;
}
#post-content #post-body .post .post-sub-title {
    font-weight: 600;
    color: #2d2d2d;
    margin-bottom: 20px;
    margin-top: 30px;
    line-height: 30px;
    font-size: 20px;
}
#post-content #post-body .post .post-description{
  line-height: 26px;
  font-size: 16px;
  color: #6a6a6a;
  margin-bottom: 20px;
  margin-top: 30px;
}
#post-content li {
  list-style: none;
}

#post-content #post-body .post .meta {
    margin-bottom: 30px;
}

#post-content #post-body .post p {
    line-height: 26px;
    font-size: 16px;
    color: #6a6a6a;
}

#post-content #sidebar .widget {
    margin-bottom: 50px;
}

#post-content #sidebar .widget-title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
    color: #3f3d59;
}

#post-content #sidebar #popular-posts.widget .item {
    margin: 0;
    margin-bottom: 20px;
}

#post-content #sidebar #popular-posts.widget .item .thumb {
    padding: 0;
    overflow: hidden;
}

#post-content #sidebar #popular-posts.widget .item .thumb img {
    width: 135%;
}

#post-content #sidebar #popular-posts.widget .item .info .date {
    font-size: 13px;
    color: #b7b7b7;
    display: block;
}

#post-content #sidebar #popular-posts.widget .item .info .title a {
    font-weight: 600;
    font-size: 14px;
    color: #3f3d59;
    text-decoration: none;
}

#post-content #sidebar #popular-posts.widget .item .info .title a:hover,
#post-content #sidebar #popular-posts.widget .item .info .title a:focus {
    color: #018EE1;
}

#post-content #sidebar.sidebar {
    padding-left: 40px;
}

#page-content {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #ffffff;
}

#page-content .content-holder {}

#page-content ul,
#page-content ol {
    padding: 0;
    padding-left: 14px;
    margin-bottom: 20px;
}

#page-content ul li,
#page-content ol li {
    color: #7a7896;
    font-size: 16px;
}

#page-content h4 {
    font-size: 17px;
    color: #3f3d59;
    font-weight: 600;
}

#page-content p {
    font-size: 16px;
    color: #7a7896;
    font-weight: 300;
    margin-bottom: 30px;
}

.post-options {
    position: relative;
    padding: 20px 0px;
    border-top: 1px solid #f0f0f0;
}

.post-options .post-tags {
    position: relative;
    float: right;
    margin-bottom: 20px;
    line-height: 24px;
}

.post-options .post-tags li {
    position: relative;
    float: left;
    margin-right: 15px;
    list-style: none;
}

.post-options .post-tags li a {
    color: #6a6a6a;
}

.post-options .share-options {
    position: relative;
    float: left;
    line-height: 24px;
}

.post-options .share-options li {
    position: relative;
    float: left;
    margin-right: 15px;
    list-style: none;
    font-size: 16px;
}

.post-options .share-options li a .fa {
    color: #6a6a6a;
}

.social-share {
    margin-top: 5px;
}
/* ---------------------------------------

Blog

--------------------------------------- */

#blog {
    text-decoration: none;
}

.blog-card img {
    width: 100%;
    -webkit-transition: .4s;
    transition: .4s;
}

.blog-description {
    padding: 25px 25px 15px;
}

.blog-frame {
    border-radius: 10px 10px 0 0;
    position: relative;
    overflow: hidden;
}

.blog-card {
    z-index: 9;
    cursor: pointer;
    background-color: rgb(250, 251, 255);
    overflow: hidden;
    height: 100%;
    position: relative;
    border-radius: 10px;
    -webkit-box-shadow: 0 10px 30px 0 rgba(5, 16, 44, .15);
    box-shadow: 0 10px 30px 0 rgba(5, 16, 44, .15);
    -webkit-transition: .4s;
    transition: .4s;
}

.blog-card:hover {
    -webkit-box-shadow: 0 20px 30px 0 rgba(5, 16, 44, .18);
    box-shadow: 0 20px 30px 0 rgba(5, 16, 44, .20);
    -webkit-transform: translateY(-3px) scale(1.01);
    transform: translateY(-3px) scale(1.01);
}

.blog-card:hover.blog-card img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.more-tooltip {
    opacity: 0;
    position: absolute;
    margin-top: -17.5px;
    width: 35px;
    color: rgb(250, 251, 255);
    border-radius: 50%;
    text-align: center;
    z-index: 999999999999;
    -webkit-box-shadow: 0 2px 400px 5000px rgba(0, 0, 0, 0.40);
    box-shadow: 0 2px 400px 5000px rgba(0, 0, 0, 0.40);
    -webkit-transition: .4s;
    transition: .4s;
}

.more-tooltip:hover {
    color: rgb(250, 251, 255);
    -webkit-transform: translateY(-3px) scale(1.01);
    transform: translateY(-3px) scale(1.01);
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.blog-card:hover .more-tooltip {
    opacity: 1;
}

#blog p {
    text-decoration: none;
}

.newspostcardoverlay {
    min-height: 500px;
    border-radius: 10px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    margin: 0 auto;
    width: 80%;
    /* background-image: url(../images/blog.jpg), linear-gradient(180deg, rgba(32, 46, 61, .25), #202e3d); */
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
}

.newspostcardoverlay:hover {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    box-shadow: 0 7px 34px 0 rgba(0, 0, 0, .35);
}

.newspostcardauthor {
    color: #ffffff;
    text-align: center;
    letter-spacing: 2.1px;
    text-transform: uppercase;
    margin-top: 40px;
    padding-top: 23vw;
    font-weight: 400;
}

.newspostcardname {
    min-height: 0;
    border-bottom: 1px none hsla(0, 0%, 100%, .17);
    color: #fff;
    font-size: 24px;
    line-height: 39px;
}

.newspostcardname,
.newspostcardsummary {
    width: 80%;
    text-align: center;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.newspostcardline {
    display: block;
    width: 50px;
    height: 2px;
    margin-right: auto;
    margin-bottom: 23px;
    margin-left: auto;
    background-color: #fafafa;
}

.newspostcardsummary {
    padding-bottom: 40px;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
}


/*------------------------------------------------------------------
    Contact Header Styles
-------------------------------------------------------------------*/
#contact{
  /* background: #f9f7f6; */
}
#contact b {
  color: #018EE1;
  font-weight: 600;
  text-decoration: none;
}
.row-title{
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    color: #2d2d2d;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
}
/* .row-title:after{
    position: absolute;
    content: "";
    width: 90px;
    height: 5px;
    background-color: #2d2d2d;
    left: 49%;
    margin-left: -29px;
    bottom: -15px;
} */
.contact-header-description{
  text-align: center;
  margin-bottom: 2em;
  line-height: 32px;
}
.contact-helpdesk-heading{
  display: block;
  line-height: 40px;
  text-align: left;
  text-transform: none;
}
.contact-helpdesk-description{
  text-align: left;
  display: block;
  margin-right: auto;
  margin-left: auto;
  color: #6A6A6A;
  padding-bottom: 10px;
  line-height: 30px;
}
.contact-wrap-wrap{
  margin-bottom: 50px;
}


/*------------------------------------------------------------------
    Contact Form
-------------------------------------------------------------------*/
#content {
  font-size: 18px;
  padding: 1em 0;
}

#content h2 {
  margin: 0 0 2em;
  opacity: 0.1;
}

#content p {
  margin: 1em 0;
  padding: 5em 0 0 0;
  font-size: 0.65em;
}
.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-bottom: 15px;
  /* max-width: 80%; */
  width: calc(100% - 0em);
  vertical-align: top;
}

.input-block-field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  border: none;
  border-radius: 0;
  background: #f0f0f0;
  color: #aaa;
  font-weight: 400;
  -webkit-appearance: none; /* for box shadows to show on iOS */
}
.input-block-field:focus {
  outline: none;
}
.input-label {
  display: inline-block;
  float: right;
  padding: 0 1em;
  width: 40%;
  color: #696969;
  font-weight: bold;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input-label-content {
  position: relative;
  display: block;
  padding: 1.6em 0;
  width: 100%;
}
.graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}

.icon {
  color: #ddd;
  font-size: 150%;
}

/* Fumi */
.input-block {
  background: #fff;
  overflow: hidden;
  padding: 0.25em 0;
  border-radius: 4px;
  box-shadow: 0px 0px 5px -2px #ccc;
  -webkit-box-shadow: 0px 0px 5px -2px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    0px 0px 5px -2px #ccc;  /* Firefox 3.5 - 3.6 */
}

.input-block::after {
  content: '';
  width: 1px;
  position: absolute;
  top: 0.5em;
  bottom: 0.5em;
  left: 2.5em;
  background: #f0f0f0;
  z-index: 100;
}

.input-field {
  background: transparent;
  padding: 1.5em 1em 0.5em 3.15em;
  width: 100%;
  font-weight: 600;
  color: #2b2b2b;
  padding-left: 64px;
}

.input-field-label {
  position: absolute;
  width: 100%;
  text-align: left;
  padding-left: 4.5em;
  pointer-events: none;
}

.form-icon {
  width: 2em;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1em 0 0 0.5em;
}

.input-field-label-content {
  padding: 1.7em 0;
  display: inline-block;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.input-field-label-content span {
  display: inline-block;;
}

.input-field:focus + .input-field-label .input-field-label-content,
.input--filled .input-field-label-content {
  -webkit-animation: anim-fumi-1 0.3s forwards;
  animation: anim-fumi-1 0.3s forwards;
}

.input-field:focus + .input-error .input-field-label .input-label-content,
.input-error .input-label-content {
  color:#ff2d55 !important;
}
.input-field:focus + .input-warning .input-field-label .input-label-content,
.input-warning .input-label-content {
  color:#ff954f !important;
}

.input-field:focus + .input-error .input-field-label .form-icon,
.input-error .form-icon {
  color:#ff2d55 !important;
}

.input-field:focus + .input-warning .input-field-label .form-icon,
.input-warning .form-icon {
  color:#ff954f !important;
}

.input-error {
  border-bottom: 1px solid #ff2d55;
  color: #ff2d55 !important;
}
.input-warning {
  border-bottom: 1px solid #ff954f;
  color: #ff954f !important;
}
@media (min-width: 1200px){
.col-lg-offset-5 {
    margin-left: 41.66666667%;
}
}

@-webkit-keyframes anim-fumi-1 {
  50% {
    -webkit-transform: translate3d(0, 3em, 0);
    transform: translate3d(0, 3em, 0);
  }
  51% {
    -webkit-transform: translate3d(0, -3em, 0) scale3d(0.85, 0.85, 1);
    transform: translate3d(0, -3em, 0) scale3d(0.85, 0.85, 1);
  }
  100% {
    /* color: #a3a3a3; */
    -webkit-transform: translate3d(0, -1.1em, 0) scale3d(0.85, 0.85, 1);
    transform: translate3d(0, -1.1em, 0) scale3d(0.85, 0.85, 1);
  }
}

@keyframes anim-fumi-1 {
  50% {
    -webkit-transform: translate3d(0, 3em, 0);
    transform: translate3d(0, 3em, 0);
  }
  51% {
    -webkit-transform: translate3d(0, -3em, 0) scale3d(0.85, 0.85, 1);
    transform: translate3d(0, -3em, 0) scale3d(0.85, 0.85, 1);
  }
  100% {
    /* color: #a3a3a3; */
    -webkit-transform: translate3d(0, -1.1em, 0) scale3d(0.85, 0.85, 1);
    transform: translate3d(0, -1.1em, 0) scale3d(0.85, 0.85, 1);
  }
}


.input-field:focus + .input-field-label .form-icon,
.input--filled .form-icon {
  -webkit-animation: anim-fumi-2 0.3s forwards;
  animation: anim-fumi-2 0.3s forwards;
}


@-webkit-keyframes anim-fumi-2 {
  50% {
    opacity: 1;
    -webkit-transform: translate3d(0, -3em, 0);
    transform: translate3d(0, -3em, 0);
  }
  50.25% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3em, 0);
    transform: translate3d(0, -3em, 0);
  }
  50.75% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3em, 0);
    transform: translate3d(0, 3em, 0);
  }
  51% {
    opacity: 1;
    -webkit-transform: translate3d(0, 3em, 0);
    transform: translate3d(0, 3em, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    /* color: #00aeef; */
  }
}

@keyframes anim-fumi-2 {
  50% {
    opacity: 1;
    -webkit-transform: translate3d(0, -3em, 0);
    transform: translate3d(0, -3em, 0);
  }
  50.25% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3em, 0);
    transform: translate3d(0, -3em, 0);
  }
  50.75% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3em, 0);
    transform: translate3d(0, 3em, 0);
  }
  51% {
    opacity: 1;
    -webkit-transform: translate3d(0, 3em, 0);
    transform: translate3d(0, 3em, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    /* color: #00aeef; */
  }
}

/*------------------------------------------------------------------
    Contact button
-------------------------------------------------------------------*/


/* General styles for all types of buttons */

.progress-button {
  position: relative;
  display: inline-block;
  padding: 0 60px;
  outline: none;
  border: none;
  background: #018ee1;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1em;
  line-height: 4;
  float: right;
  border-radius: 4px;
}

.alert{
  padding: 0px 60px;
  line-height: 4;
  border: 1px solid #5BC236;
  border-radius: 4px;
  margin-bottom: 0px !important;
}

.alert-dismissable .close, .alert-dismissible .close {
  position: relative;
  top: 18px;
  right: -21px;
  color: inherit;
  color: #5BC236;
  background-color: #CEEDC3;
}
.form-submission-alert{
  padding: 0px;
}
.prog-button{
  padding: 0px;
}

.progress-button[disabled],
.progress-button[disabled].state-loading {
  cursor: default;
}

.progress-button .content {
  position: relative;
  display: block;
}

.progress-button .content::before,
.progress-button .content::after  {
  position: absolute;
  right: 20px;
  color: #fff;
  font-family: "icomoon";
  opacity: 0;
  -webkit-transition: opacity 0.3s 0.3s;
  transition: opacity 0.3s 0.3s;
  width: 40%;
  font-size: 160%;
}

.progress-button .content::before {
  content: "\e001"; /* Checkmark for success */
}

.progress-button .content::after {
  content: "\e002"; /* Cross for error */
}
.progress-button.state-success{
  background-color: #5BC236;
}
.progress-button.state-error {
  background-color: #ff2d55;
}
.progress-button.state-warning {
  background-color: #ff954f;
}
.progress-button.state-success .content::before,
.progress-button.state-error .content::after {
  opacity: 1;
}

.notransition {
  -webkit-transition: none !important;
  transition: none !important;
}

.progress-button .progress {
  background: #99D2F3;
}

.progress-button .progress-inner {
  position: absolute;
  left: 0;
  background: #0180CB;
}

.progress-button[data-horizontal] .progress-inner {
  top: 0;
  width: 0;
  height: 100%;
  -webkit-transition: width 0.3s, opacity 0.3s;
  transition: width 0.3s, opacity 0.3s;
}



/* Button */
/* ====================== */

.progress-button[data-style="fill"][data-horizontal] {
  overflow: hidden;
}

.progress-button[data-style="fill"][data-horizontal] .content {
  z-index: 10;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  font-weight: 600;
}

.progress-button[data-style="fill"][data-horizontal] .content::before,
.progress-button[data-style="fill"][data-horizontal] .content::after {
  top: 70%;
  right: auto;
  left: 50%;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.progress-button[data-style="fill"][data-horizontal].state-success .content,
.progress-button[data-style="fill"][data-horizontal].state-error .content {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

/*------------------------------------------------------------------
    Contact write us to
-------------------------------------------------------------------*/


.email,
.careers-email{
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  margin-bottom: 50px;
}
.footer-wrap{
  width: 90%;
  margin-left: 5%;
}

/*------------------------------------------------------------------
  Careers
-------------------------------------------------------------------*/



.careers {
 top: -130px;
 top: calc(190px * -1 + 60px);
}
 .careers {
 position: relative;
 background: -webkit-gradient(linear,left top,left bottom,color-stop(1%,#f6bf4e),color-stop(23%,#f9d281),color-stop(71%,#fffdf3));
 background: linear-gradient(-180deg,#f6bf4e 1%,#f9d281 23%,#fffdf3 71%);
 overflow: hidden;
}
.careers {
   position: absolute;
   width: 100%;
   height: 100%;
   top: -80px;
   top: calc(265px * -1 + 110px);
   -webkit-transform: skewY(-12deg);
   transform: skewY(-12deg);
}
.careers .s1 {
 top: 0;
 left: 0;
 width: 30%;
 background: -webkit-gradient(linear,left top,right top,from(#ffad5a),to(rgba(250,175,82,.25)));
 background: linear-gradient(90deg,#ffad5a,rgba(250,175,82,.25));
}
.careers .career {
 position: absolute;
 height: 190px;
}

.careers .s2 {
 top: 190px;
 left: 0;
 width: 38%;
 background: -webkit-gradient(linear,left top,right top,from(#ffcd68),to(rgba(255,199,107,0)));
 background: linear-gradient(90deg,#ffcd68,rgba(255,199,107,0));
}
.careers .career {
 position: absolute;
 height: 190px;
}
.careers .s3 {
 top: 380px;
 top: calc(190px * 2);
 right: 0;
 width: 36%;
 background: -webkit-gradient(linear,left top,right top,from(hsla(46,98%,78%,0)),to(#ffda84));
 background: linear-gradient(90deg,hsla(46,98%,78%,0),#ffda84);
}
.careers .career {
 position: absolute;
 height: 190px;
}

/*------------------------------------------------------------------
    Responsive
-------------------------------------------------------------------*/

@media (max-width: 1440px){
  .contact-discussion-image{
    top: 10em !important;
  }
  .contact-pot-image{
    /* width: 10% !important; */
    top: 10em !important ;
  }
  .landing-title{
    padding-top: 5rem;
  }
}
@media (max-width: 1315px){
  .contact-discussion-image{
    top: 30px !important;
  }
  .contact-pot-image{
    width: 0% !important;
  }
  .landing-title{
    padding-top: 0rem;
  }
}
@media (max-width: 1280px){
  .contact-discussion-image{
    top: 17em !important
  }
  .lms{
    padding: 20px;
  }
  #left figure ul{
    padding: 0px !important;
  }
  .newspostcardauthor{
    padding-top: 32vw;
  }
  .landing-title{
    padding-top: 0rem;
  }
}
@media (max-width: 1000px){

  .contact-discussion-image{
    top: 23rem !important;
    width: 43% !important;
  }
  .pt-lg-lg{
    padding-top: 4rem !important;
  }
  .newspostcardauthor {
    padding-top: 40vw;
  }
}
@media (max-width: 900px){
  .contact-discussion-image{
    top: 26rem !important;
    width: 43% !important;
  }
}
@media (max-width: 850px){
  .contact-discussion-image{
    top: 28rem !important;
    /* width: 46% !important; */
  }
  .lms{
    padding: 20px;
  }
  #left figure ul{
    padding: 0px !important;
  }
  .display-2{
    font-size: 2.1rem !important;
  }
}
@media (max-width: 768px){
  .contact-wrap-wrap{
    padding: 0px;
  }
  .blog-cards{
    padding-bottom: 30px !important;
  }
  .display-2{
    font-size: 2.1rem !important;
  }
  .mt-xxl,
.my-xxl {
    margin-top: 15rem !important;
}
}
@media (max-width: 760px){
  .contact-discussion-image{
    width: 0px!important;
  }
  .pt-lg-lg{
    padding-top: 4rem !important;
  }
  .blog-cards{
    padding-bottom: 30px !important;
  }
  .lms{
    padding: 20px;
  }
  #left figure ul{
    padding: 0px !important;
  }
}
@media (max-width: 480px){
  .contact-wrap-wrap{
    margin-bottom: 30px;
  }
  .contact-helpdesk{
    padding: 0px;
  }
  .email{
    margin-bottom: 25px !important;
  }
  .careers-email{
    margin-bottom: 25px !important;
  }
  .team{
    height: 220px !important;
  }
  .pt-lg-lg{
    padding-top: 2rem !important;
  }
  .newspostcardauthor {
    padding-top: 57vw;
  }
  .section{
    padding-bottom: 2rem !important;
  }
  .section-lg{
    padding-top: 2rem;
  }
  .mt-xxl,
  .my-xxl {
    margin-top: 6rem !important;
}
  .section-profile-cover {
      height: 250px;
  }
  .featured-article-title {
    font-size: 18px;
    line-height: 36px !important;
  }
  .featured-article-subtitle{
    font-size: 14px;
  }
  .section-title b{
    font-size: 20px;
    text-align: center
  }
  .section-title .thin {
    font-size: 20px;
    text-align: center
  }
  .blog-title{
    font-size: 18px;
    padding-top:15px;
    padding-bottom: 15px;
  }
  .profile-page .card-profile .card-profile-image img{
    max-width: 85%;
  }
}
@media (max-width: 320px){
  .newspostcardauthor {
    padding-top: 77vw;
  }
  .display-2{
    font-size: 2.1rem;
  }
  .section{
    padding-bottom: 0rem !important;
  }
  .section-lg{
    padding-top: 2rem;
  }
  .card-topics{
    padding-bottom: 25px;
  }
  .row-grid + .row-grid{
    margin-top: 0;
  }
}

.uclid-stripes-1 {
  top: 0;
  grid: repeat(5,200px)/repeat(10,1fr);
  -webkit-transform-origin: 0;
  transform-origin: 0;
  background: -webkit-gradient(linear,left top,left bottom,from(#507eb1),color-stop(10%,#709dc7),color-stop(38%,#dde9f5),color-stop(48%,#eaf2f9),color-stop(62%,#f6f9fc));
  background: linear-gradient(#507eb1,#709dc7 10%,#dde9f5 38%,#eaf2f9 48%,#f6f9fc 62%);
}
.uclid-stripes-1 {
  position: absolute;
  /* z-index: -1; */
  width: 100%;
  display: grid;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
}

.uclid-stripes-1 :first-child {
  grid-column: span 3;
  background: -webkit-gradient(linear,left top,right top,from(rgba(243,251,255,.4)),color-stop(20%,rgba(243,251,255,.15)),to(rgba(243,251,255,0)));
  background: linear-gradient(100grad,rgba(243,251,255,.4),rgba(243,251,255,.15) 20%,rgba(243,251,255,0));
}
.uclid-stripes-1 :nth-child(2) {
  grid-area: 3/span 3/auto/-1;
}
@media (min-width: 670px){
.uclid-stripes-1 :nth-child(2) {
    background: -webkit-gradient(linear,left top,right top,from(rgba(0,119,204,0)),color-stop(40%,rgba(0,119,204,.05)),to(rgba(0,119,204,.35)));
    background: linear-gradient(100grad,rgba(0,119,204,0),rgba(0,119,204,.05) 40%,rgba(0,119,204,.35));
  }
}
.uclid-stripes-1 :nth-child(3) {
  grid-row: 4;
  grid-column: span 5;
}
@media (min-width: 670px){
.uclid-stripes-1 :nth-child(3) {
    background: -webkit-gradient(linear,left top,right top,color-stop(50%,#fff),to(hsla(0,0%,100%,0)));
    background: linear-gradient(100grad,#fff 50%,hsla(0,0%,100%,0));
  }
}
@media (min-width: 670px){
.uclid-stripes-1 :nth-child(4) {
background: -webkit-gradient(linear,left top,right top,color-stop(10%,rgba(0,119,204,0)),to(rgba(0,119,204,.05)));
background: linear-gradient(100grad,rgba(0,119,204,0) 10%,rgba(0,119,204,.05));
}
}
.uclid-stripes-1 :nth-child(4) {
grid-area: 4/span 5/auto/-1;
}
.uclid-stripes-1 :nth-child(5) {
grid-area: auto/1/-1/-1;
background: -webkit-gradient(linear,left top,right top,color-stop(80%,#fff),to(#f5fafd));
background: linear-gradient(100grad,#fff 80%,#f5fafd);
}


.stripes {
    height: 100%;
    grid: repeat(2,200px)/repeat(3,1fr);
    -ms-flex-line-pack: justify;
    align-content: space-between;
    background: linear-gradient(192deg,#b2cbe1,#f6f9fc 42%);
}
.stripes :first-child {
    grid-column: 3;
    background: -webkit-gradient(linear,left top,right top,from(rgba(241,248,252,0)),to(#f1f8fc));
    background: linear-gradient(100grad,rgba(241,248,252,0),#f1f8fc);
}
.circular{
  margin-bottom: 100px;
  width: 100%;
  height: 600px;
}

.module-name{
  font-weight: 600;
}
.modules-content-title {
    text-align: center;
    font-weight: 700;
    line-height: 42px;
    width: 100%;
}

.modules-content p {
    text-align: center;
    font-size: 18px;
    line-height: 36px;
    width: 100%;
}

ul .modules-uclid-description{
  list-style-type: circle;
}

.modules-list{
  width: 100px !important;
}
.digitalize{

}
/*-----------------------------------------------------
Feature Box
-------------------------------------------------------*/
.featured-section{
	background-color: #eff2f5;
}
.featured-item{
	padding: 20px;
	transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
}
.featured-item:hover{
	background-color: #fff;
}
.featured-item .icon-left,
.fun-facts-item .icon-left{
	float: left;
}
.featured-item.border-box {
    border: 1px solid #ffcc00;
}
.des{
	overflow: hidden;
}
.des-title{
	font-size: 20px;
	font-weight: 700;
	text-transform: capitalize;
}

.featured-item .icon {
	margin-bottom: 30px;
}
.featured-item .icon img{
	width: 50px;
	height: auto;
}
.ie11 .featured-item .icon img{
	height: 50px;
}
.featured-item.icon-left .icon {
	margin-bottom: 0;
}
.featured-item.icon-left {
	display: table;
}
.featured-item.icon-left .icon {
	display: table-cell;
	vertical-align: top;
}

.featured-item.icon-left.icon-left .des {
	margin-left: 30px;
}
.featured-item .des p{
	margin-bottom: 0;
}

.perks{
  width: 100%;
}

.perks ul {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    padding-left: 0px;
}

.perks ul li {
  padding: 13px;
  font-size: 16px;
  font-weight: 600;
  color: #6a6a6a;
  cursor: pointer;
}

.perks li:hover{
  /* background-color: #fff; */
}
.perks-points{
  margin-top: 2rem;
}

#product figure img {
    margin: 0 auto 5px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    box-shadow: 0 6px 12px -2px rgba(50,50,93,.25), 0 3px 7px -3px rgba(0,0,0,.3);
    padding: 3px;
}
.product-modules button{
  background-color: transparent;
  border: none;
}
.product-modules .module-name{
  padding-top: 10px;
}
button:focus {
  outline:0;
}

.gallery-1{
  min-height: 650px;
  border-radius: 10px;
  width: 100%;
}
.gallery-2{
  min-height: 200px;
  border-radius: 10px;
  width: 100%;
}

.featured-article {
position: relative;
min-height: 400px;
max-width: 1080px;
margin: 0px;
display: block;
overflow: hidden;
border-radius: 5px;
box-shadow: 0 30px 60px -12px rgba(50, 50, 93, .25), 0 18px 36px -18px rgba(0, 0, 0, .3), 0 -12px 36px -8px rgba(0, 0, 0, .025);
transition: .2s ease-out
}

@media(min-width:880px) {
.featured-article {
  display: flex;
  align-items: stretch;
  padding: 20px
}
.featured-article:focus,
.featured-article:hover {
  transform: translateY(-3px);
  box-shadow: 0 18px 35px rgba(50, 50, 93, .1), 0 8px 15px rgba(0, 0, 0, .07)
}
.featured-article:focus .featured-article-title,
.featured-article:hover .featured-article-title {
  color: #525f7f
}
}

.keyboard-navigation .featured-article:focus {
outline: none;
text-decoration: underline;
text-underline-position: under;
color: #32325d
}

.featured-article-image {
position: relative
}

@media(min-width:880px) {
.featured-article-image {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden
}
.featured-article-image:before {
  content: "";
  width: 48%;
  position: absolute;
  left: 0;
  top: 50%;
  height: 150%;
  background-color: #fff;
  transform: translate(-60px, -50%) rotate(12deg);
  z-index: 1;
  transition: transform .2s ease-out
}
}

.featured-article-image>img {
position: relative;
width: 100%;
object-fit: cover;
max-height: 60vw
}

@media(min-width:880px) {
.featured-article-image>img {
  position: absolute;
  max-height: none;
  width: 65%;
  right: 0;
  height: 100%
}
}

.featured-article-content {
flex: 0.35;
display: flex;
flex-direction: column;
justify-content: center;
padding: 30px 20px;
position: relative;
z-index: 2
}

@media(min-width:880px) {
.featured-article-content {
  padding: 20px 0 30px 20px
}
}

.featured-article-content>* {
z-index: 1
}

.featured-article-date {
margin-top: 20px;
color: #aab7c4;
text-transform: uppercase;
font: 600 13px Camphor
}

@media(min-width:880px) {
.featured-article-date {
  margin-top: auto
}
}

.featured-article-type {
font-size: 15px;
font-weight: 500;
color: #aab7c4;
text-transform: uppercase;
margin: 0 0 12px
}

.featured-article-title {
font-weight: 500;
margin: 0 0 10px;
line-height: 32px;
color: #2d2d2d;
line-height: 42px;
transition: color .1s
}

.featured-article-subtitle,
.featured-article-title {
max-width: 330px
}


.twentytwenty .slider {
  height: 50px;
  width: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -22px;
  margin-top: -22px;
  border: 3px solid white;
  background: #fff;
  -webkit-border-radius: 1000px;
  -moz-border-radius: 1000px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  z-index: 40;
  cursor: pointer;
}

.slider :before {
  bottom: 50%;
  margin-bottom: 28px;
  -webkit-box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  background: #c1c1c1;
  box-shadow: none;
}

.slider :after {
  top: 50%;
  margin-top: 28px;
  -webkit-box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  background: #c1c1c1;
  box-shadow: none;
}

.slider-left-arrow {
  border-right: 6px solid #5499d2;
  left: 50%;
  margin-left: -17px;
}

.slider-right-arrow {
  border-left: 6px solid #5499d2;
  right: 50%;
  margin-right: -17px;
}

.ba_labels {
	position: relative;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: space-around;
	text-transform: uppercase;
	color: #018ee1;
	font-weight: 700;
	font-size: 1.375rem;
	line-height: 1
}
.ba_labels .before-label {
	opacity: .62
}
.ba_labels .after-label {
	opacity: .38
}

/*------------------------------------------------------------------
    Contact Fprm
-------------------------------------------------------------------*/

.form-control{
  font-weight: 600;
  color: #2d2d2d;
}

.form-control.is-invalid, .was-validated .form-control:invalid{
  /* border-color: #ff954f !important; */
  background-image: none;
}
.invalid-feedback{
  font-weight: 500;
  color: #ff2d55 !important
}
.invalid{
  font-weight: 500;
  color: #ff2d55 !important;
  font-size: 80%;
}
label{
  font-weight: 700;
}
.partner-button{
  margin-top: 5px;
}

/*------------------------------------------------------------------
	7. Pricing Section Styles
-------------------------------------------------------------------*/

.Header {
margin: 0 auto;
position: relative;
top: 500px;
}

.pricing-background {
top: calc(50% + 175px);
}
.pricing-background {
position: absolute;
left: 0;
top: 50%;
right: 0;
-webkit-transform: skewY(-12deg);
transform: skewY(-12deg);
pointer-events: none;
}
.pricing-background .uclid {
height: 155px;
}
.pricing-background .u0 {
height: 5000px;
bottom: 10px;
background: -webkit-gradient(linear,left top,right top,from(#b3e895),to(#4dc076));
background: linear-gradient(90deg,#b3e895,#4dc076);
}
.pricing-background .uclid {
position: absolute;
top: auto;
left: 0;
right: 0;
}
.pricing-background .uclid.pattern {
overflow: hidden;
}
.pricing-background .u2 {
bottom: 310px;
bottom: calc(155px * 2);
right: calc(51% + 260px);
left: auto;
width: 30%;
min-width: 450px;
/* background: -webkit-gradient(linear,left top,right top,from(#0aab88),to(#3ebc7f));
background: linear-gradient(90deg,#0aab88,#3ebc7f); */
background: -webkit-gradient(linear,left top,right top,from(#b6e3a8),to(#dbf0af));
background: linear-gradient(90deg,#b6e3a8,#dbf0af);
opacity: .75;
}

.pricing-background .uclid.pattern:after {
content: "";
position: absolute;
left: 0;
right: 0;
bottom: -1000px;
top: -1000px;
-webkit-transform: skew(0,12deg);
transform: skew(0,12deg);
}
.pricing-background .u3:after {
background: url(images/background-pattern.svg);
opacity: .25;
}
.pricing-background .u1 {
bottom: 465px;
bottom: calc(155px * 3);
right: 80%;
background: -webkit-gradient(linear,left top,right top,from(#bfd4ff),to(#d2f0f9));
background: linear-gradient(90deg,#bfd4ff,#d2f0f9);
opacity: .2;
}
.pricing-background .u3 {
bottom: 155px;
bottom: calc(155px * 1);
left: 75%;
/* background: -webkit-gradient(linear,left top,right top,from(#b6e3a8),to(#dbf0af));
background: linear-gradient(90deg,#b6e3a8,#dbf0af); */
background: -webkit-gradient(linear,left top,right top,from(#0aab88),to(#3ebc7f));
background: linear-gradient(90deg,#0aab88,#3ebc7f);
}
.pricing-background .u4 {
bottom: 0;
background: -webkit-gradient(linear,left top,right top,from(#d7f8b7),to(#98e3b5));
background: linear-gradient(90deg,#d7f8b7,#98e3b5);
}

#pricing{
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
}
#pricing .bg-color{
    background-color: #d6e0f5;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    -webkit-transform: skewY(-10deg) scaleY(0.7);
    transform: skewY(-10deg) scaleY(0.7);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    z-index: -1;
}
.pricing-box{
    text-align: center;
    margin-top: 80px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    position: sticky;
}
.pricing-box:hover{
    /*transform: scale(1.02);*/
}
.pricing-box .pricing-content{
    border: 1px solid #e0e0e8;
    background-color: #fcfcfd;
    -webkit-box-shadow: 0 9px 18px rgba(0,0,0,0.08);
    -moz-box-shadow: 0 9px 18px rgba(0,0,0,0.08);
    box-shadow: 0 9px 18px rgba(0,0,0,0.08);
    border-radius: 10px;
    position: relative;
    padding: 70px 27px 27px 27px;
    z-index: -1;
}
.pricing-box.pricing-box-simple .pricing-content{
    -webkit-box-shadow: 0 9px 18px rgba(0,0,0,0.0);
    -moz-box-shadow: 0 9px 18px rgba(0,0,0,0.0);
    box-shadow: 0 9px 18px rgba(0,0,0,0.0);
}
.pricing-box.no-icon .pricing-content{
    padding: 40px 27px 27px 27px;
}
.pricing-box:hover .pricing-content{
    background-color: #fdfdfd;
    -webkit-box-shadow: 0 9px 48px rgba(0,0,0,0.12);
    -moz-box-shadow: 0 9px 48px rgba(0,0,0,0.12);
    box-shadow: 0 9px 48px rgba(0,0,0,0.12);
}
.pricing-box.pricing-box-simple:hover .pricing-content{
    -webkit-box-shadow: 0 9px 18px rgba(0,0,0,0.0);
    -moz-box-shadow: 0 9px 18px rgba(0,0,0,0.0);
    box-shadow: 0 9px 18px rgba(0,0,0,0.0);
}
.pricing-box .pricing-content .pricing-icon{
    position: absolute;
    width: 88px;
    height: 88px;
    left: 50%;
    margin-left: -44px;
    top: -44px;
    border-radius: 200px;
    overflow: hidden;
    z-index: -100;
}
.pricing-box.pricing-box-simple .pricing-content .pricing-icon{
    width: 76px;
    height: 76px;
    margin-left: -38px;
    top: -38px;
}
.pricing-box:hover .pricing-content .pricing-icon .special-gradiant{
    animation-name: little-move;
}
.pricing-box .pricing-content .pricing-icon .htfy{
    color: #ffffff;
    font-size: 45px;
    margin-top: 21px;
}
.pricing-box.pricing-box-simple .pricing-content .pricing-icon .htfy{
    font-size: 33px;
}
.pricing-box .pricing-content .pricing-icon .htfy-technology{
    margin-top: 26px;
}
.pricing-box.pricing-box-simple .pricing-content .pricing-icon .htfy-technology{
    margin-top: 23px;
}
.pricing-box .pricing-content .pricing-icon .fa{
    color: #ffffff;
    font-size: 40px;
    margin-top: 25px;
}
.pricing-box .pricing-content .pricing-icon img{
    position: absolute;
    width: 50px;
    height: 65px;
    left: 50%;
    top: 35%;
    margin-left: -25px;
    margin-top: -22px;
}
.pricing-box.pricing-box-simple .pricing-content .pricing-icon .fa{
    font-size: 28px;
}
.pricing-box.pricing-box-simple .pricing-content .pricing-icon .htfy{
    color: #a1a1a1;
}
.pricing-box.pricing-box-simple .pricing-content .pricing-icon .fa{
    color: #a1a1a1;
}
.pricing-box.pricing-box-simple .pricing-content .pricing-icon img{
    -webkit-filter: grayscale(100%) brightness(88%);
    filter: grayscale(100%) brightness(88%);
}
.pricing-box.pricing-color1 .pricing-content .pricing-icon{
    /* background-color: #756de7; */
    background-color: #ffffff;
}
.pricing-box.pricing-color2 .pricing-content .pricing-icon{
    background-color: #ffffff;
}
.pricing-box.pricing-color3 .pricing-content .pricing-icon{
    background-color: #65d692;
}
.pricing-box.pricing-box-simple .pricing-content .pricing-icon{
    border: 1px solid #e0e0e8;
    background-color: #fcfcfd;
}

.pricing-box.pricing-box-simple.pricing-color1:hover .pricing-content .pricing-icon{
    border-color: #5b5b5c;
    background-color: #333333;
}
.pricing-box.pricing-box-simple.pricing-color2:hover .pricing-content .pricing-icon{
    border-color: #5d9cec;
    background-color: #e4f0ff;
}

.pricing-box.pricing-box-simple:hover:before{
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
}
.pricing-box.pricing-box-simple.pricing-color1:before{
    color: #5b5b5c;
}
.pricing-box.pricing-box-simple.pricing-color2:before{
    color: #5d9cec;
}

.pricing-box.pricing-box-simple:after{
    position: absolute;
    content: "Select";
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    color: #3f3d59;
    top: 55px;
    right: 30px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 0;
}
.pricing-box.pricing-box-simple:hover:after{
    right: 26px;
    opacity: 1;
}
.pricing-box.featured .pricing-content:after{
    position: absolute;
    content: "\f005";
    font-family: "fontAwesome";
    font-size: 14px;
    top: 0;
    left: 20px;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 2px 5px;
    color: #ffffff;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.pricing-box.featured:hover .pricing-content:after{
    padding: 4px 5px;
}
.pricing-box.featured.pricing-color1 .pricing-content:after{
    background-color: #5b5b5c;
}
.pricing-box.featured.pricing-color2 .pricing-content:after{
    background-color: #5d9cec;
}
.pricing-box.featured.pricing-color3 .pricing-content:after{
    background-color: #65d692;
}
.pricing-box .pricing-content .pricing-title{
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
}
.pricing-content .pricing-title-module{
    color: #4dc076;
    font-size: 17px;
    font-weight: 600;
    padding-bottom: 20px;
}
.pricing-box.pricing-color1 .pricing-content .pricing-title{
    color: #5b5b5c;
}
.pricing-box.pricing-color2 .pricing-content .pricing-title{
    color: #5d9cec;
}
.pricing-box.pricing-color3 .pricing-content .pricing-title{
    color: #65d692;
}
.pricing-box .pricing-content .pricing-price{
    font-size: 17px;
    color: #a3a3a3;
}
.pricing-box .pricing-content .pricing-details{
    text-align: left;
}
.pricing-box .pricing-content .pricing-details ul{
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 40px;
    margin-bottom: 20px;
}
.pricing-box .pricing-content .pricing-details ul li{
    font-size: 18px;
    color: #777493;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    position: relative;
}
.pricing-box .pricing-content .pricing-details ul li:hover{
    padding-left: 50px;
    font-weight: 700;
}
.pricing-box .pricing-content .pricing-details ul li:before{
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 10px;
    /* background: url(images/svg/circle-checkmark.svg); */
    left: 0;
    top: 50%;
    margin-top: -5px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 3px 8px rgba(0,0,0,0.15);
    -moz-box-shadow: 0 3px 8px rgba(0,0,0,0.15);
    box-shadow: 0 3px 8px rgba(0,0,0,0.15);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.pricing-box .pricing-content .pricing-details ul li:hover:before{
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
}
 .pricing-box.pricing-color1 .pricing-content .pricing-details ul li:before{
    background-color: #5b5b5c;
    -webkit-box-shadow: 0 3px 8px rgba(45, 45, 45, 0.5);
    -moz-box-shadow: 0 3px 8px rgba(45, 45, 45, 0.5);
    box-shadow: 0 3px 8px rgba(45, 45, 45, 0.5);
}
.pricing-box.pricing-color2 .pricing-content .pricing-details ul li:before{
    background-color: #5d9cec;
    -webkit-box-shadow: 0 3px 8px rgba(93, 156, 236, 0.5);
    -moz-box-shadow: 0 3px 8px rgba(93, 156, 236, 0.5);
    box-shadow: 0 3px 8px rgba(93, 156, 236, 0.5);
}

.pricing-box .pricing-content .pricing-details ul li.not-included{
    color: #bbbac1;
}
.pricing-box .pricing-content .pricing-details ul li.not-included:before{
    background-color: #ffffff;
    -webkit-box-shadow: 0 3px 8px rgba(0,0,0,0.15);
    -moz-box-shadow: 0 3px 8px rgba(0,0,0,0.15);
    box-shadow: 0 3px 8px rgba(0,0,0,0.15);
}
.pricing-box .pricing-content .pricing-link a{
    color: #66556b;
    background-color: #ffffff;
    /* width: 100%; */
    margin: 0;
}
.pricing-box.pricing-color1 .pricing-content .pricing-link img{
    /* color: #ffffff; */
    /* background-color: #333333; */
    width: 45px;
}
.pricing-box.pricing-color1 .pricing-content .pricing-link a:hover,
.pricing-box.pricing-color1 .pricing-content .pricing-link a:focus{
    /* color: #ffffff; */
    /* background-color: #333333; */
    border: none;
}
.pricing-box.pricing-color2 .pricing-content .pricing-link a{
    color: #ffffff;
    background-color: #018ee1;
    border: none;
}
.pricing-box.pricing-color2 .pricing-content .pricing-link a:hover,
.pricing-box.pricing-color2 .pricing-content .pricing-link a:focus{
    color: #ffffff;
    background-color: #528fdb;
}
.pricing-box.pricing-color3 .pricing-content .pricing-link a{
    color: #ffffff;
    background-color: #65d692;
}
.pricing-box.pricing-color3 .pricing-content .pricing-link a:hover,
.pricing-box.pricing-color3 .pricing-content .pricing-link a:focus{
    color: #ffffff;
    background-color: #5cc686;
}

#page-icon .pricing-icon{
    position: absolute;
    width: 88px;
    height: 88px;
    left: 50%;
    margin-left: -44px;
    top: -44px;
    border-radius: 200px;
    overflow: hidden;
    z-index: 100;
}
#page-icon:hover .pricing-icon .special-gradiant{
    animation-name: little-move;
}
#page-icon .pricing-icon .htfy{
    color: #ffffff;
    font-size: 45px;
    margin-top: 21px;
}
#page-icon .pricing-icon .htfy-technology{
    margin-top: 26px;
}
#page-icon .pricing-icon .fa{
    color: #ffffff;
    font-size: 40px;
    margin-top: 25px;
}
#page-icon .pricing-icon.pricing-color1{
    background-color: #5b5b5c;
}
#page-icon .pricing-icon.pricing-color2{
    background-color: #5d9cec;
}
/*------------------------------------------------------------------
    Responsive
-------------------------------------------------------------------*/

@media (max-width: 850px){
  .landing{
    padding: 0px 30px;
  }
}
@media (max-width: 768px){
  .free-to-use{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  #product .digital{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  #product .communication{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  #product .assess-your-progress{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 760px){
  .responsive-module{
    padding-left: 35px;
  }
  .responsive-gallery{
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
@media (max-width: 720px){
  .display-3{
    font-size: 1.85rem !important;
  }
}
@media (max-width: 480px){
  .perks ul{
    column-count: 1;
  }
  .storebuttons{
    width: 172px;
  }
}
@media (max-width: 400px){
  .storebuttons{
    width: 155px;
  }
}
@media (max-width: 360px){
  .storebuttons{
    width: 150px;
  }
}
@media (max-width: 330px){
  .storebuttons{
    width: 140px;
  }
}
@media (max-width: 300px){
  .storebuttons{
    width: 140px;
  }
}
@media (min-width: 1200px){
  .col-lg-offset-1 {
      margin-left: 8.33333333%;
  }
}
.wrapper {
  width: 100%;
  position: relative;
  background-color: #fff;
}
.wrapper:after {
  padding-top: 42.85714285714287%;
  display: block;
  content: '';
}
.wrapper .column {
  position: absolute;
  left: 0;
  top: 0;
  width: 14.2857142857142857%;
  float: left;
  padding: 6px;
  box-sizing: border-box;
}
.wrapper .column .inner {
  width: 100%;
  position: relative;
  border-radius: 10px;
}
.wrapper .column .inner:after {
  padding-top: 100%;
  /* ratio 1:1 */
  display: block;
  content: '';
}
.wrapper .column .inner:before {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
  /* background-color: rgba(0, 0, 0, 0.4); */
}
.wrapper .column .inner:hover:before {
  background-color: rgba(255, 255, 255, 0);
}
.wrapper .column:nth-child(1) {
  width: 43%;
}
/* .wrapper .column:nth-child(2) {
  left: 28.5714285714285714%;
} */
/* .wrapper .column:nth-child(3) {
  left: 28.5714285714285714%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
} */
.wrapper .column:nth-child(4) {
  width: 28.5714285714285714%;
  left: 42.85714285714286%;
  top: 0;
}
.wrapper .column:nth-child(4) .inner:after {
  padding-top: 48.7%;
}
.wrapper .column:nth-child(5) {
  left: 71.42857142857143%;
  top: 0;
  bottom: 0;
  width: 28.5714285714285714%;
}

/* .wrapper .column:nth-child(6) {
  left: auto;
  right: 0;
  top: 0;
}
.wrapper .column:nth-child(7) {
  left: 71.42857142857143%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.wrapper .column:nth-child(8) {
  left: auto;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
} */
/* .wrapper .column:nth-child(9) {
  width: 28.5714285714285714%;
  top: auto;
  left: 0;
  bottom: 0;
} */
/* .wrapper .column:nth-child(9) .inner:after {
  padding-top: 48.7%;
} */
/* .wrapper .column:nth-child(10) {
  left: 28.5714285714285714%;
  top: auto;
  bottom: 0;
} */
.wrapper .column:nth-child(11) {
  left: 42.85714285714286%;
  top: auto;
  bottom: 0;
  width: 28.5714285714285714%;
}
.wrapper .column:nth-child(12) {
  left: auto;
  top: auto;
  bottom: 0;
  right: 0;
  width: 28.5714285714285714%;
}
.wrapper .column:nth-child(12) .inner:after {
  padding-top: 48.7%;
}
/* BEAUTY */
body,
html {
  background-color: #fff;
  padding: 0;
}
.wrapper .column .inner {
  background-size: cover;
  background-position: center;
}
.wrapper .column:nth-child(1) .inner {
  background-image: url('images/gallery/2.jpg');
}
/* .wrapper .column:nth-child(2) .inner {
  background-image: url('images/gallery/2.jpg');
} */
/* .wrapper .column:nth-child(3) .inner {
  background-image: url('images/gallery/3.jpg');
} */
.wrapper .column:nth-child(4) .inner {
  background-image: url('images/gallery/4.jpg');
}
.wrapper .column:nth-child(5) .inner {
  background-image: url('images/gallery/1.jpg');
}
/* .wrapper .column:nth-child(6) .inner {
  background-image: url('images/gallery/6.jpg');
}
.wrapper .column:nth-child(7) .inner {
  background-image: url('images/gallery/2.jpg');
}
.wrapper .column:nth-child(8) .inner {
  background-image: url('images/gallery/1.jpg');
} */
/* .wrapper .column:nth-child(9) .inner {
  background-image: url('images/gallery/4.jpg');
} */
/* .wrapper .column:nth-child(10) .inner {
  background-image: url('images/gallery/2.jpg');
} */
.wrapper .column:nth-child(11) .inner {
  background-image: url('images/gallery/3.jpg');
}
.wrapper .column:nth-child(12) .inner {
  background-image: url('images/gallery/4.jpg');
}
.wrapper .column:nth-child(13) .inner {
  background-image: url('images/gallery/6.jpg');
}

/* How it Works */


.how_it_works {
  text-align: center;
  position: relative
}

.how_it_works.has_border:after {
  content: "";
  display: block;
  width: 12%;
  height: 4px;
  background: rgba(0, 0, 0, .1);
  -webkit-border-radius: 2px;
  border-radius: 2px;
  position: absolute;
  right: -10.5%;
  top: 62px
}

.how_it_works .image {
  width: 120px;
  height: 120px;
  line-height: 100px;
  text-align: center;
  position: relative;
  margin: 0 auto 15px;
  background: #fff;
  border-width: 2px;
  /* border-style: solid; */
  border-radius: 100%
}

.how_it_works .image img, svg {
  max-width: 55px!important;
  max-height: 55px!important;
  vertical-align: middle
}

.how_it_works .image .number {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  text-align: center;
  border-radius: 100%;
  /* -webkit-box-shadow: inset 0 0 0 2px rgba(0, 0, 0, .1); */
  /* box-shadow: inset 0 0 0 2px rgba(0, 0, 0, .1) */
}

.with_aside .how_it_works .image {
  width: 146px;
  height: 146px;
  line-height: 144px
}

.with_aside .how_it_works .image img, svg {
  max-width: 90px!important;
  max-height: 90px!important
}

.with_aside .how_it_works .image .number {
  bottom: 4px;
  right: 4px
}

.with_aside .how_it_works.has_border:after {
  top: 75px
}

.how_it_works.fill .image img {
  border-radius: 100%;
  max-width: 196px!important;
  max-height: 196px!important;
}

.with_aside .how_it_works.fill .image img {
  max-width: 146px!important;
  max-height: 146px!important
}

.how_it_works.no-img .image {
  height: 18px;
  background: none;
  border-width: 0
}

.how_it_works.no-img .image .number {
  left: 50%;
  margin-left: -18px
}

.how_it_works .image .number {
  background: rgb(214, 250, 207);
}
.how_it_works .image .number {
color: rgb(62, 207, 142);
}

.one.column {
width: 98%;
}
.works .column, .columns {
float: left;
margin: 0 1% 40px;
}
.one-fourth.column, .four.columns {
width: 18%;
}
.button-ios{
  margin-left:10px !important;
  margin-right:10px !important;
}
@media only screen and (max-width: 767px){
.section_wrapper .column {
    margin: 0 0 20px;
    height: auto!important;
}
.works .column, .columns{
  float: none;
}
.one-fourth.column, .four.columns{
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}
.how_it_works.has_border:after{
  background: white;
}
}

/* Advantages */


.advantages .container {
display: grid;
grid-template-rows: repeat(3,auto);
grid-gap: 26px 0;
gap: 26px 0;
}
@media (min-width: 670px){
.advantages .container {
    grid-gap: 0 26px;
    gap: 0 26px;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(1,1fr);
}}

/*------------------------------------------------------------------
  Privacy Center
-------------------------------------------------------------------*/
.privacy-center{

}

.privacy-center .title{
  font-weight: bold;
}
.privacy-center p{
  font-weight: 500;
  color: #6a6a6a;
}
.privacy-center h3{
  padding-bottom: 20px;
}
.privacy-center .section ul li {
  list-style-type: none !important;
}

/*------------------------------------------------------------------
  Date Picker
-------------------------------------------------------------------*/
.react-datepicker-wrapper{
  width: 100% !important;
}
.react-datepicker__input-container{
  width: 100% !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected{
  background-color: #018ee1 !important;
}
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover{
  background-color: #018ee1 !important;
}
.react-datepicker__header{
  font-family: 'Montserrat', sans-serif;
  background-color: #f5f5f5 !important;
  font-weight: 600;
  color: #2d2d2d !important;
}
.react-datepicker__navigation--previous{
  border-right-color: #b2b2b2 !important;
}
.react-datepicker__navigation--next{
  border-left-color: #b2b2b2 !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}
.redBorder{
  border: 1px solid red;
}
